<template>
  <div class="col-8 mx-auto justify-content-center">
    <h3>Dodaj Element</h3>
    <form class="add-form bg-light border" @submit.prevent>
      <small>Nazwa</small>
      <input
        v-model="title"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Nazwa"
      />

      <small>Ilość</small>
      <input
        v-model="quantity"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Ilość "
      />

      <small>Uwagi</small>

      <vue-editor v-model="notice"></vue-editor>

      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addMagazineItem()"
      >
        Dodaj element
      </button>
    </form>
  </div>
</template>
<script>
import router from "../router/index";
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      title: "",
      quantity: "",
      notice: "",
      date: "",
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    // checkIfExist() {
    //   return this.payments.filter((item) => {
    //     if (this.invoiceNumber) {
    //       if (
    //         item.invoiceNumber.toUpperCase().includes(this.invoiceNumber.toUpperCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // },
  },
  components: {
    VueEditor,
  },
  methods: {
    addMagazineItem() {
      if (this.title) {
        this.$store
          .dispatch("createMagazineItem", {
            title: this.title,
            quantity: this.quantity,
            notice: this.notice,
            date: new Date(),
          })
          .then(
            (this.title = ""),
            (this.quantity = ""),
            (this.notice = ""),
            alert("Element został dodany")
          );
        router.push("/magazine");
      } else {
        alert("brak nazwy");
      }
    },
  },
};
</script>
